import { SxProps, Theme } from '@mui/material'
import { img } from '../../../../imgs'

type appBannerStylesProps =
  | 'section'
  | 'top'
  | 'topLeft'
  | 'topLeftTitle'
  | 'wrap'
  | 'topRight'
  | 'title'
  | 'titleDesc'
  | 'card'
  | 'img'
  | 'titleCard'
  | 'textCard'
  | 'btn'
  | 'btn_holder'
  | 'mainPrize'
  | 'mainPrizeTitle'
  | 'mainPrizeImg'
  | 'mainPrizeDesc'
  | 'qr'
  | 'qrDesc'
  | 'learnNew'
  | 'carouselItem'

export const appBannerStyles: Record<appBannerStylesProps, SxProps<Theme> | undefined> = {
  section: ({ breakpoints: { down } }: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    mt: '40px',
    [down('md')]: {
      mt: '-30px',
      pt: '70px',
    },
  }),
  top: ({ breakpoints: { down } }: Theme) => ({
    display: 'flex',
    flexDirection: 'row',
    mt: '20px',
    mb: '100px',
    [down('md')]: {
      flexDirection: 'column',
      mb: '100px',
    },
    [down('sm')]: {
      flexDirection: 'column',
      mb: '60px',
    },
  }),
  topLeft: ({ breakpoints: { down } }: Theme) => ({
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    [down('md')]: {
      width: '100%',
    },
  }),
  topLeftTitle: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 32,
    color: palette.primary.dark,
    fontWeight: 600,
    [down(1350)]: {
      fontSize: '2.37vw',
    },
    [down('md')]: {
      fontSize: '2.9vw',
      textAlign: 'center',
    },
    [down('sm')]: {
      fontSize: '5.33vw',
    },
  }),
  wrap: ({ breakpoints: { down }, palette }: Theme) => ({
    fontWeight: 800,
    whiteSpace: 'nowrap',
    [down('md')]: {
      whiteSpace: 'wrap',
    },
  }),
  topRight: ({ breakpoints: { down } }: Theme) => ({
    display: 'flex',
    width: '50%',
    flexDirection: 'row',
    alignItems: 'center',
    '& img': {
      height: 'auto',
      width: '30%',
      maxWidth: 200,
    },
    [down('md')]: {
      width: '100%',
    },
  }),
  title: ({ breakpoints: { down }, palette }: Theme) => ({
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 42,
    fontWeight: 800,
    textAlign: 'center',
    textTransform: 'uppercase',
    alignItems: 'center',
    alignSelf: 'center',
    padding: '0 20px',
    boxSizing: 'border-box',
    color: palette.primary.dark,
    [down(1350)]: {
      fontSize: '3.11vw',
    },
    [down('md')]: {
      fontSize: '3.81vw',
    },
    [down('sm')]: {
      fontSize: '5vw',
    },
  }),
  titleDesc: ({ breakpoints: { down }, palette }: Theme) => ({
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 24,
    fontWeight: 600,
    textAlign: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    padding: '0 20px',
    boxSizing: 'border-box',
    color: palette.primary.dark,
    m: '10px 0 60px 0',
    [down(1350)]: {
      fontSize: '1.77vw',
    },
    [down('md')]: {
      fontSize: '2.18vw',
    },
    [down('sm')]: {
      fontSize: '4vw',
    },
  }),
  card: () => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  img: ({ breakpoints: { down } }: Theme) => ({
    mb: '15px',
    height: 220,
    width: '100%',
    maxWidth: 240,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '0.2s',
    '& img': {
      width: '100%',
    },
    '&:hover': {
      transform: 'scale(1.1)',
    },
    [down(1350)]: {
      mb: '1.11vw',
      height: '16.29vw',
      maxWidth: '17.77vw',
    },
    [down('md')]: {
      mb: '2.6vw',
      height: '20vw',
      maxWidth: '20vw',
    },
    [down('sm')]: {
      m: '0 auto 5vw',
      height: 'auto',
      maxWidth: '60vw',
    },
  }),
  titleCard: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 24,
    fontWeight: 800,
    m: '0 auto 15px',
    textAlign: 'center',
    color: palette.primary.dark,
    textTransform: 'uppercase',
    [down(1350)]: {
      fontSize: '1.77vw',
      m: '0 auto 15px',
    },
    [down('md')]: {
      fontSize: '2.18vw',
    },
    [down('sm')]: {
      fontSize: '4vw',
    },
  }),
  textCard: ({ breakpoints: { down }, palette }: Theme) => ({
    m: '0 auto',
    fontSize: 16,
    width: '100%',
    maxWidth: 306,
    fontWeight: 500,
    textAlign: 'center',
    color: palette.primary.dark,
    [down(1350)]: {
      fontSize: '1.18vw',
      maxWidth: '19.25vw',
    },
    [down('md')]: {
      fontSize: '1.45vw',
      maxWidth: '22vw',
    },
    [down('sm')]: {
      fontSize: '2.66vw',
      maxWidth: '70vw',
    },
  }),
  btn_holder: ({ breakpoints: { down }, palette }: Theme) => ({
    width: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    mt: 4,
    [down('sm')]: {
      mt: 10,
    },
  }),
  btn: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: '18px',
    fontWeight: 700,
    maxHeight: 50,
    width: 1,
    maxWidth: 350,
    borderRadius: '110px',
    backgroundColor: '#E51523',
    '& a': {
      color: '#FFFFFF',
      textDecoration: 'none',
    },
    ':hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      color: '#E51523',
    },
    [down(1350)]: {
      maxHeight: '3.7vw',
      fontSize: '1.33vw',
      maxWidth: '25.92vw',
    },
    [down('md')]: {
      maxHeight: '4.54vw',
      fontSize: '1.63vw',
      maxWidth: '280px',
    },
    [down('sm')]: {
      maxHeight: '12vw',
      fontSize: '4vw',
      maxWidth: 1,
    },
  }),
  mainPrize: ({ breakpoints: { down } }: Theme) => ({
    display: 'flex',
    width: '100%',
    mt: '120px',
    flexWrap: 'wrap',
    justifyContent: 'center',
    [down('sm')]: {
      flexDirection: 'column',
      mt: '80px',
    },
  }),
  mainPrizeTitle: ({ breakpoints: { down }, palette }: Theme) => ({
    display: 'flex',
    width: '45%',
    fontWeight: 800,
    fontSize: '42px',
    color: palette.primary.dark,
    m: '0 100px 40px 100px',
    textAlign: 'center',
    [down(1400)]: {
      width: '50%',
    },
    [down(1350)]: {
      fontSize: '3.11vw',
    },
    [down('md')]: {
      fontSize: '3.81vw',
    },
    [down('sm')]: {
      m: '0',
      fontSize: '7vw',
      width: '100%',
      mb: '40px',
    },
  }),
  mainPrizeImg: ({ breakpoints: { down } }: Theme) => ({
    display: 'flex',
    width: '45%',
    height: 'fit-content',
    m: 'auto 0',
    [down('sm')]: {
      width: '100%',
    },
  }),
  mainPrizeDesc: ({ breakpoints: { down }, palette }: Theme) => ({
    display: 'flex',
    width: '45%',
    flexDirection: 'column',
    fontSize: '24px',
    fontWeight: 600,
    color: palette.primary.dark,
    ml: 'auto',
    '& p': {
      m: '12px 0',
    },
    [down(1350)]: {
      fontSize: '1.77vw',
    },
    [down('md')]: {
      fontSize: '2.18vw',
    },
    [down('sm')]: {
      width: '100%',
      mt: '40px',
      fontSize: '4vw',
    },
  }),
  qr: ({ breakpoints: { down }, palette }: Theme) => ({
    height: 180,
    width: 180,
    [down('md')]: {
      display: 'none',
    },
  }),
  qrDesc: ({ breakpoints: { down }, palette }: Theme) => ({
    m: 'auto 0 auto 20px',
    color: '#484848',
    '& > div > span': {
      fontWeight: 600,
      fontSize: '22px',
      ml: '12px',
      [down(1350)]: {
        fontSize: '1.62vw',
      },
      [down('md')]: {
        fontSize: '2vw',
      },
      [down('sm')]: {
        fontSize: '3.66vw',
      },
    },
    '& > div': {
      m: '10px 0',
    },
    '& img': {
      height: 20,
      width: 20,
    },
  }),
  learnNew: ({ breakpoints: { down }, palette }: Theme) => ({
    m: 'auto 0 auto 20px',
    fontSize: '16px',
    fontWeight: 600,
    ml: '30px',
    '& a': {
      color: palette.secondary.contrastText,
      textDecoration: 'underline',
    },
    [down(1350)]: {
      fontSize: '1.18vw',
    },
    [down('md')]: {
      fontSize: '1.45vw',
      ml: '0',
      mt: '20px',
    },
    [down('sm')]: {
      fontSize: '4vw',
    },
  }),
  carouselItem: ({ breakpoints: { down }, palette }: Theme) => ({
    width: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'uppercase',
    flexDirection: 'column',
    fontSize: '24px',
    fontWeight: 800,
    color: palette.primary.dark,
    '& img': {
      width: '80%',
    },
  }),
}
