import { FC, JSX } from 'react'
import { Box, Container, Grid, Button, Theme, useMediaQuery } from '@mui/material'
import { appBannerStyles } from './appbanner.styles'
import { img } from '../../../../imgs'

export const AppBanner: FC = (): JSX.Element => {
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  return (
    <Box sx={appBannerStyles.section}>
      <Container maxWidth="lg" sx={appBannerStyles.top}>
        <Box sx={appBannerStyles.topLeft}>
          <Box sx={appBannerStyles.topLeftTitle}>
            Покупайте контактные линзы ACUVUE
            <Box
              component="span"
              fontSize={{ xs: '3.33vw', sm: '1.81vw', md: '20px' }}
              style={{ verticalAlign: 'super' }}
            >
              ®
            </Box>{' '}
            <Box component="span" sx={appBannerStyles.wrap}>
              с приложением MyACUVUE
              <Box
                component="span"
                fontSize={{ xs: '3.33vw', sm: '1.81vw', md: '20px' }}
                style={{ verticalAlign: 'super' }}
              >
                ®
              </Box>
            </Box>{' '}
            и участвуйте автоматически,{' '}
            <Box component="span" sx={appBannerStyles.wrap}>
              без загрузки чеков!
            </Box>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'column', marginTop: md ? '20px' : '50px' }}>
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
              <Box component="img" src={img.prizes_qr} sx={appBannerStyles.qr} />
              <Box sx={appBannerStyles.qrDesc}>
                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Box component="img" src={img.prizes_check_mark} alt="" />
                  <Box component="span">Все для заботы о зрении в одном приложении</Box>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Box component="img" src={img.prizes_check_mark} alt="" />
                  <Box component="span">
                    Кешбэк баллами и акции от ACUVUE
                    <Box
                      component="span"
                      fontSize={{ xs: '1.66vw', sm: '0.9vw', md: '10px' }}
                      style={{ verticalAlign: 'super' }}
                    >
                      ®
                    </Box>
                  </Box>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Box component="img" src={img.prizes_check_mark} alt="" />
                  <Box component="span">Возможность заказать доставку линз в оптику</Box>
                </Box>
              </Box>
            </Box>
            <Box style={{ display: md ? 'none' : 'flex', flexDirection: 'row', marginTop: '14px' }}>
              <Button sx={appBannerStyles.btn}>
                <a href="https://myacuvue.onelink.me/o8U8/ad9cbu3v" target="_blank" rel="noopener noreferrer">
                  Зарегистрироваться
                </a>
              </Button>
              <Box sx={appBannerStyles.learnNew}>
                <a href="https://l.ead.me/bfLLGE" target="_blank" rel="noopener noreferrer">
                  Узнать больше
                </a>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={appBannerStyles.topRight}>
          <Box component="img" src={img.prizes_top3} alt="" mt="3vw" ml="auto" />
          <Box component="img" src={img.prizes_top1} alt="" />
          <Box component="img" src={img.prizes_top2} alt="" mt="3vw" mr="auto" />
        </Box>
        <Box
          style={{
            display: md ? 'flex' : 'none',
            flexDirection: 'column',
            marginTop: '40px',
            alignItems: 'center',
          }}
        >
          <Button sx={appBannerStyles.btn}>
            <a href="https://myacuvue.onelink.me/o8U8/ad9cbu3v" target="_blank" rel="noopener noreferrer">
              Зарегистрироваться
            </a>
          </Button>
          <Box sx={appBannerStyles.learnNew}>
            <a href="https://l.ead.me/bfLLGE" target="_blank" rel="noopener noreferrer">
              Узнать больше
            </a>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
